<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div>
      <div class="body">
        <h3 style="font-weight: bold;font-size: 30px;text-align: center">巡查方案<a-button style="float: right;margin: 10px" v-if="$route.params.showType=='edit'" type="primary" @click="confirm">生成巡查方案</a-button></h3>
        <a-form-model ref="queryForm" :model="queryParams" layout="inline" :rules="formRules" class="query-form" style="margin-left: 83px">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="项目巡查的总要求" prop="requirements">
                <a-textarea v-model.trim="queryParams.requirements" placeholder="填写针对项目范围内不同区域采用的巡查方式（说明哪些区域采用系统巡查、哪些区域采用视频巡查、哪些区域采用现场巡查，它们之间的关系）、责任岗位以及对应要求等内容" cols="75" rows="3" :disabled="$route.params.showType!='edit'"></a-textarea>
              </a-form-model-item>
              <a-form-model-item label="巡查路线设置原则" prop="principle" style="margin-top: 15px">
                <a-textarea v-model.trim="queryParams.principle" placeholder="填写视频巡查线路设置所遵循的基本原则。如：从哪个区域到哪个区域、楼层从上到下、从哪条车道到哪条车道等" cols="75" rows="3" :disabled="$route.params.showType!='edit'"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="巡查区域划分情况" prop="areadivision">
                <a-textarea v-model.trim="queryParams.areadivision" placeholder="填写巡查线路在项目各区域的分布情况，并简要说明覆盖的范围" cols="75" rows="2" :disabled="$route.params.showType!='edit'"></a-textarea>
              </a-form-model-item>
              <a-form-model-item label="巡查区域的关注点" prop="focuspoint">
                <a-textarea v-model.trim="queryParams.focuspoint" placeholder="填写视频巡查过程中需要关注的事项，对于一些需重点关注的巡查点位要有特别的说明" cols="75" rows="2" :disabled="$route.params.showType!='edit'"></a-textarea>
              </a-form-model-item>
              <a-form-model-item label="报告和调度的规定" prop="regulation" style="margin-bottom: 10px">
                <a-textarea v-model.trim="queryParams.regulation" placeholder="填写遇到什么情况、该怎么处理、向谁汇报等内容" cols="75" rows="2" :disabled="$route.params.showType!='edit'"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="body">
        <a-table :columns="columns" :rowKey="(record,index)=> index" :data-source="tableData" :pagination="false" bordered>
          <template
              v-for="col in ['patroltype', 'managetype', 'linenumber', 'inspectionarea','frequency','post','remark']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <a-select
                  placeholder=""
                  v-if="col == 'patroltype'"
                  :disabled="$route.params.showType!='edit'"
                  style="width: 100px;"
                  v-model="record.patroltype"
              >
                <a-select-option :value="0">系统巡查</a-select-option>
                <a-select-option :value="1">视频巡查</a-select-option>
<!--                <a-select-option :value="2">现场巡查</a-select-option>-->
              </a-select>
              <a-input
                  placeholder="请输入管理类型"
                  v-if="col == 'managetype'"
                  style="margin: -5px 0"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.managetype"
                  :value="text"
              />
              <a-textarea
                  placeholder="请输入线路编号"
                  v-if="col == 'linenumber'"
                  style="margin: -5px 0;width: 120px"
                  rows="1"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.linenumber"
                  :value="text"
              />
              <a-textarea
                  placeholder="请输入巡查区域/位置"
                  v-if="col == 'inspectionarea'"
                  rows="1"
                  style="margin: -5px 0"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.inspectionarea"
                  :value="text"
              />
              <a-input
                  placeholder="请输入频次/时段"
                  v-if="col == 'frequency'"
                  style="margin: -5px 0"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.frequency"
                  :value="text"
              />
              <a-input
                  placeholder="请输入呼叫岗位"
                  v-if="col == 'post'"
                  style="margin: -5px 0"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.post"
                  :value="text"
              />
              <a-input
                  placeholder="请输入备注"
                  v-if="col == 'remark'"
                  style="margin: -5px 0"
                  :disabled="$route.params.showType!='edit'"
                  v-model="record.remark"
                  :value="text"
              />
            </div>
          </template>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :disabled="$route.params.showType!='edit'" :key="'add-'+record.keynum">新增</a-menu-item>
                <a-menu-item :disabled="$route.params.showType!='edit'" :key="'delete-'+record.keynum">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </div>
    </div>
  </page-container>
</template>

<script>
import {mapState} from "vuex";
import {getItemFromArrayByKey} from "U/index";
import {getMonitorPointNameList} from "A/monitoring";
import {addPatrolScheme, addPatrolSchemeData, deletePatrolSchemeData} from "A/patrol";
import {patrolShifts} from "@/json/wlwhistory";
export default {
  name: "patrolScheme",
  data(){
    return {
      patrolShifts,
      columns:[
        {
          title: '操作',
          dataIndex: 'operation',
          width: 40,
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
        {
          title: '序号',
          dataIndex: 'keynum',
          width: 40,
        },
        {
          title: '巡查方式',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 100,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          width: 180,
          scopedSlots: { customRender: 'managetype' },
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          width: 80,
          scopedSlots: { customRender: 'linenumber' },
        },
        {
          title: '巡查区域/位置（摄像机编号）',
          dataIndex: 'inspectionarea',
          scopedSlots: { customRender: 'inspectionarea' },
        },
        {
          title: '频次/时段',
          dataIndex: 'frequency',
          width: 120,
          scopedSlots: { customRender: 'frequency' },
        },
        {
          title: '呼叫/巡查岗位',
          dataIndex: 'post',
          width: 120,
          scopedSlots: { customRender: 'post' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 160,
          scopedSlots: { customRender: 'remark' },
        },
      ],
      tableData:[
        {
          patrolschemedataid:null,
          keynum: 1,
          patroltype: 1,
          managetype: '巡查管理-公区',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 2,
          patroltype: 1,
          managetype: '巡查管理-公区',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 3,
          patroltype: 1,
          managetype: '巡查管理-公区',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 4,
          patroltype: 1,
          managetype: '巡查管理-电梯',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 5,
          patroltype: 1,
          managetype: '巡查管理-电梯',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 6,
          patroltype: 1,
          managetype: '巡查管理-电梯',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 7,
          patroltype: 1,
          managetype: '巡查管理-车场',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 8,
          patroltype: 1,
          managetype: '巡查管理-车场',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 9,
          patroltype: 1,
          managetype: '巡查管理-车场',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 10,
          patroltype: 1,
          managetype: '巡查管理-岗位',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 11,
          patroltype: 1,
          managetype: '出入管理-行人出入口',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 12,
          patroltype: 1,
          managetype: '出入管理-大堂',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 13,
          patroltype: 1,
          managetype: '出入管理-车辆出入口',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 14,
          patroltype: 1,
          managetype: '设施设备-设备房',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 15,
          patroltype: 1,
          managetype: '设施设备-重点设施设备',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 16,
          patroltype: 1,
          managetype: '设施设备-抄表',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 17,
          patroltype: 1,
          managetype: '恶劣天气-危险位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 18,
          patroltype: 1,
          managetype: '恶劣天气-项目最低位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 19,
          patroltype: 1,
          managetype: '恶劣天气-雨后积水位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 20,
          patroltype: 1,
          managetype: '重点抽查位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        // {
        //   patrolschemedataid:null,
        //   keynum: 21,
        //   patroltype: 1,
        //   managetype: '随机抽查',
        //   linenumber: '',
        //   inspectionarea: '',
        //   frequency:'',
        //   post:'',
        //   remark:'',
        // },
      ],
      formRules: {
        patrolname: [{required: true, message: '请输入巡查方案名'}],
        patrolshift: [{required: true, message: '请选择巡查班次'}],
        timerange: [{required: true, message: '请输入巡查时间段'}],
        requirements: [{required: true, message: '请输入项目巡查的总要求'}],
        areadivision: [{required: true, message: '请输入巡查区域划分情况'}],
        focuspoint: [{required: true, message: '请输入巡查区域的关注点'}],
        principle: [{required: true, message: '请输入巡查路线设置原则'}],
        regulation: [{required: true, message: '请输入报告和调度的规定'}],
      },
      queryParams:{
        monitorpointid:'',
        monitorpointnum:'',
        patrolname:'',
        patrolshift:'',
        requirements:'',
        areadivision:'',
        focuspoint:'',
        principle:'',
        regulation:'',
        timerange:'',
      },
      monitorpointNameList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "巡查方案"
    },
    breadcrumb() {
      const pages = [{name:this.$route.params.modalTitle,path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
    if(this.$route.params.scheme){
      this.queryParams=this.$route.params.scheme
    }
    if(this.$route.params.schemeData){
      this.tableData=this.$route.params.schemeData
    }
    this.getMonitorpointName()
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item.length>0?res.item[0].monitorpointnum:""
          this.queryParams.patrolname=res.item.length>0?res.item[0].monitorpointname+"巡查方案":""
        }
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let keyid = arr[1];
      let type = arr[0];
      let record = getItemFromArrayByKey(this.tableData, 'keynum', keyid);
      if(type=='add'){
        this.tableData.splice(record.keynum,0, {
          keynum: record.keynum+1,
          patroltype: '',
          managetype: '',
          lineNumber: '',
          inspectionArea: '',
          frequency:'',
          post:'',
          remark:'',})
        for(let i=record.keynum+1;i<this.tableData.length;i++){
          this.tableData[i].keynum++
        }
      }else if(type=='delete'){
        deletePatrolSchemeData({patrolschemedataid:record.patrolschemedataid})
        this.tableData.splice(record.keynum-1,1)
        for(let i=record.keynum-1;i<this.tableData.length;i++){
          this.tableData[i].keynum--
        }
      }
    },
    confirm() {
      this.$refs.queryForm.validate(valid => {
        if (valid) {
          this.queryParams.recordman=this.userInfo.username
          let params = {
            ...this.queryParams
          }
          addPatrolScheme(params).then(res => {
            if (res && res.returncode == '0') {
              let params = {
                patrolschemeid: res.id,
                addoredit: this.tableData[0].patrolschemedataid ? 'edit' : 'add',
                data: this.tableData
              }
              addPatrolSchemeData(params).then(res => {
                if (res && res.returncode == '0') {
                  // this.$message.success("操作成功")
                  // this.$router.go(-1)
                  let that = this
                  this.$confirm('您已修改巡查方案，原有模板已删除，请重新生成新的巡查模板。',{
                    title: '修改成功',
                    type:'warning',
                    centered:true,
                  }).then(()=>{
                    that.$message.success("操作成功")
                    that.$router.go(-1)
                  }).catch(()=>{
                    that.$message.success("操作成功")
                    that.$router.go(-1)
                  });
                  // this.$confirm({
                  //   title: '修改成功',
                  //   content: '您已修改巡查方案，原有模板已删除，请重新生成新的巡查模板。',
                  //   maskClosable:false,
                  //   onOk() {
                  //     that.$message.success("操作成功")
                  //     that.$router.go(-1)
                  //     // that.$router.push({
                  //     //   name:"patrolSetting",
                  //     //   params:{
                  //     //     tabskey:'2'
                  //     //   }
                  //     // })
                  //   },
                  //   onCancel() {
                  //     that.$message.success("操作成功")
                  //     that.$router.go(-1)
                  //     // that.$router.push({
                  //     //   name:"patrolSetting",
                  //     //   params:{
                  //     //     tabskey:'1'
                  //     //   }
                  //     // })
                  //   },
                  // });
                }else{
                  this.$message.error(res.errormsg)
                }
              })
            }
            else{
              this.$message.error(res.errormsg)
            }
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.body{
  background-color: white;
  margin: 10px;
}
</style>